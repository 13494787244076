import { IUser, IFacilitator, IPartner, IDeliveryPartner, IPlayer, FACILITATOR_REVIEW_RESULT } from '../models/IUser';
import { ICohort, IRemainingSessions } from '../models/ICohort';
import Auth from './Auth';
import { AUDIENCE_TYPE, HTTP_METHOD, USER_ROLE } from '../constants';
import Environment from './Environment';

export interface IUserApiBundle {
  userId?: string, //optional- CREATE if not supplied, UPDATE if supplied
  email: string, 
  firstName: string, 
  lastName: string,
  
  country?: string,
  sport?: string,

  DeliveryPartner_PartnerId?: string
  
  Facilitator_ReviewResult?: FACILITATOR_REVIEW_RESULT,

  Facilitator_PartnerId?: string
  Facilitator_DeliveryPartnerId?: string
}

export interface IFacilitatorReviewApiBundle {
  userId: string,
  reviewResult: FACILITATOR_REVIEW_RESULT
}

export interface ICohortApiBundle {
  cohortId?: string,  //optional- CREATE if not supplied, UPDATE if supplied
  title: string,
  postcode: string,
  audience: AUDIENCE_TYPE,

  ageGroup: string,
  gender: string,
  clubName: string,
  clubContactName: string,
  clubContactPhoneNumber: string,
  clubContactEmail: string,
  equipmentAvailable?: string,
  notes?: string,

  isDemo?: boolean,
  
  country?: string,
  sport?: string,
  
  partnerId?: string,
  deliveryPartnerId?: string,
  facilitatorId?: string
}
export interface ISessionUpdateApiBundle {
  cohortId?: string,  //optional- CREATE if not supplied, UPDATE if supplied
  sessionStartDateTime?: string,
  sessionEndDateTime?: string,
  headCount?: number,
}
export interface ISessionApiBundle{
  sessionId: string,
  cohortId: string,
  type: string,
  facilitatorId: string,
  deliveryDate: string,
  isTeamFeedbackEnabled?: boolean,
  teams?: string,
  headCount?: number,
}
export interface IUserStatistics {
  upcomingSessionCount: number | undefined;
  completedSessionCount: number | undefined;
  completedChapter2Percentage: number | undefined;
  completedChapter5Percentage: number | undefined;
};

export default {
  async _executeApi(method: string, endpoint: string, params: any = {}): Promise<any> {
    let fetchConfig: any = {
      method: method,
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Auth.getAccessToken()}`
      })
    };

    let urlParams = new URLSearchParams();

    if(method === HTTP_METHOD.GET) {
      Object.keys(params).forEach(key => {
        const paramValue = params[key];
        if(paramValue !== undefined && paramValue !== null) {
          urlParams.append(key, paramValue);
        }
      })
    } else {
      fetchConfig.body = JSON.stringify(params);
    }

    const urlParamsString = urlParams.toString()
      ? `?${urlParams.toString()}`
      : '';

    const res = await fetch(`${endpoint}${urlParamsString}`, fetchConfig);
    if (res.status === 200) {
      try {
        return await res.json();
      } catch (e) {
        return;
      }
    } else {
      // throw new Error(res.statusText);
      // This should give better error description
      const error = await res.json();
      throw new Error(error.body);
    }
  },

  async getPartners(): Promise<IPartner[]> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/partners`);
  },
  async getDeliveryPartners(forPartnerId?: string): Promise<IDeliveryPartner[]> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/deliverypartners`, { partnerId: forPartnerId});
  },

  //if no deliveryPartnerId unspecified, route will try to return self (if user has partner role)
  async getDeliveryPartnerById (deliveryPartnerId?: string): Promise<IDeliveryPartner | undefined> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/deliverypartners`, { deliveryPartnerId: deliveryPartnerId });
  },
  
  //if no partnerId unspecified, route will try to return self (if user has partner role)
  async getPartnerById (partnerId?: string): Promise<IPartner | undefined> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/partners`, { partnerId: partnerId });
  },

  //if no partnerId unspecified, route will try to return faciitators for self (if user has partner role)
  async getFacilitators (forPartnerId?: string, forDeliveryPartnerId?: string): Promise<IFacilitator[]> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/facilitators`, { partnerId: forPartnerId, deliveryPartnerId: forDeliveryPartnerId });
  },

  async getFacilitatorById (forFacilitatorId: string): Promise<IFacilitator | undefined> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/facilitators`, { facilitatorId: forFacilitatorId });
  },

  async getCohorts (forPartnerId?: string, forFacilitatorId?: string, forDeliveryPartnerId?: string): Promise<ICohort[]> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/cohorts`, {
      partnerId: forPartnerId,
      facilitatorId: forFacilitatorId,
      deliveryPartnerId: forDeliveryPartnerId
    });
  },

  async getCohortById(cohortId: string): Promise<ICohort | undefined> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/cohorts`, { cohortId: cohortId })
  },

  async getPlayers (config: {
    forPartnerId?: string
    forFacilitatorId?: string
    forCohortId?: string
    forDeliveryPartnerId?: string
  }): Promise<IPlayer[]> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/players`, { 
      partnerId: config.forPartnerId,
      facilitatorId: config.forFacilitatorId,
      cohortId: config.forCohortId,
      deliveryPartnerId: config.forDeliveryPartnerId
    });
  },
  
  async getUser(): Promise<IUser | null> {
    return this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/users`);
  },

  async reviewFacilitator(facilitatorId: string, reviewResult: FACILITATOR_REVIEW_RESULT) {
    return await this._executeApi(HTTP_METHOD.PUT, `${Environment.getCurrentEnvironment().apiUrl}/facilitators`, { userId: facilitatorId, Facilitator_ReviewResult: reviewResult });
  },

  async createOrUpdateUser(userRole: number, userBundle: IUserApiBundle) {
    const isNewUser = !userBundle.userId;
    const httpMethod = isNewUser ? HTTP_METHOD.POST : HTTP_METHOD.PUT;
    let path;
    switch (userRole) {
      case USER_ROLE.Partner:
        path = '/partners';
        break;
      case USER_ROLE.DeliveryPartner:
        path = '/deliverypartners';
        break;
      case USER_ROLE.Facilitator:
        path = '/facilitators';
        break;
      case USER_ROLE.Player:
      default:
        path = '/players';
    }

    const res = await this._executeApi(httpMethod, `${Environment.getCurrentEnvironment().apiUrl}${path}`, userBundle);

    return res;
  },


  //TODO: archive facilitators
  async getRemainingSessions(forFacilitatorId?: string): Promise<IRemainingSessions[][]> {
    return await this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/get-remaining-sessions`, { facilitatorId: forFacilitatorId });
  },
  
  async archiveFacilitator(forFacilitatorId: string) {
    return this._executeApi(HTTP_METHOD.DELETE, `${Environment.getCurrentEnvironment().apiUrl}/facilitators`, { facilitatorId: forFacilitatorId  });
  },
  
  async archivePlayer(playerId: string) {
    return this._executeApi(HTTP_METHOD.DELETE, `${Environment.getCurrentEnvironment().apiUrl}/players`, { userId: playerId });
  },

  async archiveCohort(cohortId: string) {
    return this._executeApi(HTTP_METHOD.DELETE, `${Environment.getCurrentEnvironment().apiUrl}/cohorts`, { cohortId: cohortId });
  },

  async archiveSession(cohortId: string, sessionId: string) {
    return this._executeApi(HTTP_METHOD.DELETE, `${Environment.getCurrentEnvironment().apiUrl}/sessions`, { cohortId: cohortId, sessionId: sessionId });
  },

  async getUserById(userId: string, userRole: number): Promise<IUser | undefined> {
    switch (userRole) {
      case USER_ROLE.Partner:
        return this.getPartnerById(userId);
      case USER_ROLE.DeliveryPartner:
        return this.getDeliveryPartnerById(userId);
      case USER_ROLE.Facilitator:
        return this.getFacilitatorById(userId);  
    }
  },

  async createOrUpdateCohort(cohortBundle: ICohortApiBundle) {
    const httpMethod = cohortBundle.cohortId ? HTTP_METHOD.PUT : HTTP_METHOD.POST;
    return this._executeApi(httpMethod, `${Environment.getCurrentEnvironment().apiUrl}/cohorts`, cohortBundle);
  },

  async updateSessionForSessionStart(sessionBundle: ISessionUpdateApiBundle) {
    return this._executeApi(HTTP_METHOD.PUT, `${Environment.getCurrentEnvironment().apiUrl}/sessions`, sessionBundle);
  },

  async regenerateCohortAccessCode(cohortId: string) {
    return this._executeApi(HTTP_METHOD.POST, `${Environment.getCurrentEnvironment().apiUrl}/regenerate-access-code`, { cohortId });
  },
  
  async triggerPushNotifications() {
    return this._executeApi(HTTP_METHOD.POST, `${Environment.getCurrentEnvironment().apiUrl}/push-notifications`);
  },

  async createOrUpdateSession(sessionBundle: ISessionApiBundle) {
    const httpMethod = sessionBundle.sessionId ? HTTP_METHOD.PUT : HTTP_METHOD.POST;
    return this._executeApi(httpMethod, `${Environment.getCurrentEnvironment().apiUrl}/sessions`, sessionBundle);
  },

  async getUserExport() {
    return this._executeApi(HTTP_METHOD.POST, `${Environment.getCurrentEnvironment().apiUrl}/user-export`);
  },

  async getUserStatistics(): Promise<IUserStatistics> {
    return this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/user-statistics`);
  },

  async reviewedFacilitator(facilitatorId: string, reviewResult: FACILITATOR_REVIEW_RESULT) {
    const approvalBundle = {
      userId: facilitatorId,
      Facilitator_ReviewResult: reviewResult
    }
    return this._executeApi(HTTP_METHOD.PUT, `${Environment.getCurrentEnvironment().apiUrl}/facilitators`, approvalBundle);
  },

  async getMyTasks() {
    return this._executeApi(HTTP_METHOD.GET, `${Environment.getCurrentEnvironment().apiUrl}/get-my-tasks`);
  },

  async resetPassword(email: string) {
    return this._executeApi(HTTP_METHOD.POST, `${Environment.getCurrentEnvironment().apiUrl}/reset-password`, { email });
  }
}