import React, { useState } from 'react';
import Api from '../../services/Api';
import { json2csv } from 'json-2-csv';
import exportIcon from "../../images/export.svg"; 
import { GridButton } from '../Grids/GridButton/GridButton';

export const ExportButton: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const onDownload = async () => {
        if (isLoading) return;
        setIsLoading(true);
        const exportData = await Api.getUserExport();
        console.log(exportData);
        if (exportData && exportData[0]) {
            const modifiedData = exportData[0].map((each: any)=>{each.Player_TeamFeedback = ''; each.Player_Data = ''; each.CountData = ''; return each;});
            downloadCSVFromJson('UserData.csv', modifiedData);
        }
        if (exportData && exportData[1]) {
            downloadCSVFromJson('CohortData.csv', exportData[1]);
        }
        setIsLoading(false);
    }

    const downloadCSVFromJson = (filename: string, arrayOfJson: any ) => {
        let csv = json2csv(arrayOfJson, {emptyFieldValue :''});

        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <GridButton
      icon={exportIcon}
      onClick={onDownload}
      disabled={isLoading}
      label={isLoading ? 'Loading...' : "Export All Data"}/>;
}

